import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import moment from 'moment'
import he from 'he'

import SEO from '../components/seo'
import Subscribe from '../components/subscribe'
import PageCover from '../components/page-cover'
import BlogCard from '../components/cards/blog-card'
import { ArrowDown, Close } from '../components/icons'

class BlogTemplate extends Component {
	lastScroll = 0

	state = {
		length: 12,
		visible: false,
		scrolled: false,
		filter: 'All',
	}

	componentDidMount() {
		window.addEventListener('scroll', this.scrollDetect)
		this.await = true
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollDetect)
	}

	scrollDetect = () => {
		let currentScroll =
			document.documentElement.scrollTop || document.body.scrollTop
		let { scrolled, length } = this.state
		let posts = this.props.data.allWordpressPost.edges

		if (currentScroll > 0 && this.lastScroll <= currentScroll) {
			if (!scrolled) {
				this.setState({ scrolled: true })
			}
		} else {
			if (scrolled) {
				this.setState({ scrolled: false })
			}
		}

		if (
			this.await &&
			posts.length > length &&
			currentScroll /
			(document.querySelector('.subscribe').offsetTop -
				window.innerHeight) >
			0.9
		) {
			this.await = false
			setTimeout(() => (this.await = true), 200)
			this.setState({ length: length + 3 })
		}
		this.lastScroll = currentScroll
	}

	_toggleFilter = () => {
		if (this.state.visible === true) {
			this.setState({ visible: false })
		} else {
			this.setState({ visible: true })
		}
	}

	render() {
		const page = this.props.data.wordpressPage
		let title = 'Blog'
		if (this.props.pageContext.category) {
			title = `${this.props.pageContext.category} Blog`
		}

		let { visible, filter, length, scrolled } = this.state
		const { data } = this.props
		const posts = data.allWordpressPost.edges
		const allShown = posts.length <= length
		let count = 0
		if (allShown) {
			length = posts.length
		}
		const loadMore = event => {
			event.preventDefault()
			this.setState({ length: this.state.length + 8 })
		}

		return (
			<>
				<SEO title={title} />
				{this.props.pageContext.skip === 0 && page.acf.page_cover && (
					<PageCover content={page.acf.page_cover} />
				)}
				<div className="page-cover__under">
					<section className="blogs blogs--component">
						<div
							className={`blogs__filter ${
								scrolled ? 'scrolled' : ''
							}`}
						>
							<div className="blogs__inner container">
								<div className="col">
									<h4 onClick={this._toggleFilter}>
										Filter: <span>{this.state.filter}</span>{' '}
										<ArrowDown colour="#000" />
									</h4>
								</div>
							</div>
						</div>
						<div className="blogs__inner blogs__padding container">
							<div className="blogs__items">
								{posts &&
								posts.map((el, i) => {
									let props = {
										key: i,
										image:
											el.node.acf.featured_image &&
											el.node.acf.featured_image
												.localFile &&
											el.node.acf.featured_image
												.localFile
												.childImageSharp &&
											el.node.acf.featured_image
												.localFile.childImageSharp
												.fixed.src,
										title: he.decode(el.node.title),
										link: `/${el.node.slug}`,
										author: el.node.acf.author_name
											? el.node.acf.author_name
											: el.node.author,
										date: moment(el.node.date).format(
											'MMM, YYYY'
										),
									}
									if (
										filter !== 'All' &&
										!el.node.categories.find(
											cat => cat.name === filter
										) &&
										!el.node.sector.find(
											cat => cat.name === filter
										)
									)
										return false
									if (++count > length) return false
									return <BlogCard {...props} />
								})}
							</div>
							{!allShown && (
								<a
									className="blogs__more"
									href="#more"
									onClick={loadMore}
								>
									Load more...
								</a>
							)}
						</div>
						<div
							className={`${
								visible
									? 'blogs__filter-drop active'
									: 'blogs__filter-drop'
							}  ${scrolled ? 'scrolled' : ''}`}
						>
							<span
								className="close"
								onClick={this._toggleFilter}
							>
								<Close colour="#737373" />
							</span>
							<div className="blogs__inner container">
								<div className="col">
									<h5>
										Filter: <span>{filter}</span>
									</h5>
								</div>
								<div className="col">
									<h4>Discipline</h4>
									<ul>
										{data.allWordpressCategory.edges &&
										data.allWordpressCategory.edges.map(
											(el, i) => {
												let props = {
													to: `/blog/discipline/${
														el.node.slug
													}`,
													onClick: event => {
														event.preventDefault()
														this.setState({
															filter:
															event
																.currentTarget
																.innerText,
														})
														this._toggleFilter()
														// Scroll to a certain element
														document
															.querySelector(
																'.blogs'
															)
															.scrollIntoView(
																{
																	behavior:
																		'smooth',
																	alignTo: true,
																}
															)
														if (
															window.location
																.pathname !==
															props.to
														) {
															window.history.pushState(
																window
																	.history
																	.state,
																'',
																props.to
															)
														}
													},
												}
												return (
													<li key={i}>
														<Link {...props}>
															{el.node.name}
														</Link>
													</li>
												)
											}
										)}
									</ul>
								</div>
								<div className="col">
									<h4>Sector</h4>
									<ul>
										{data.allWordpressWpSector.edges &&
										data.allWordpressWpSector.edges.map(
											(el, i) => {
												let props = {
													to: `/blog/sector/${
														el.node.slug
													}`,
													onClick: event => {
														event.preventDefault()
														this.setState({
															filter:
															event
																.currentTarget
																.innerText,
														})
														this._toggleFilter()
														// Scroll to a certain element
														document
															.querySelector(
																'.blogs'
															)
															.scrollIntoView(
																{
																	behavior:
																		'smooth',
																	alignTo: true,
																}
															)
														if (
															window.location
																.pathname !==
															props.to
														) {
															window.history.pushState(
																window
																	.history
																	.state,
																'',
																props.to
															)
														}
													},
												}
												return (
													<li key={i}>
														<Link {...props}>
															{el.node.name}
														</Link>
													</li>
												)
											}
										)}
									</ul>
								</div>
							</div>
						</div>
					</section>
					<Subscribe />
				</div>
			</>
		)
	}
}

export default BlogTemplate

export const blogQuery = graphql`
	query BlogTemplateQuery($skip: Int = 0, $limit: Int = 99999) {
		allWordpressCategory(sort: { fields: [menu_order], order: ASC }) {
			edges {
				node {
					name
					slug
				}
			}
		}
		allWordpressWpSector(sort: { fields: [menu_order], order: ASC }) {
			edges {
				node {
					name
					slug
				}
			}
		}
		allWordpressPost(
			limit: $limit
			skip: $skip
			filter: { wordpress_id: { ne: 3386 } }
			sort: { fields: [date], order: DESC }
		) {
			edges {
				node {
					title
					date
					excerpt
					slug
					categories {
						name
						slug
					}
					sector {
						name
						slug
					}
					author
					acf {
						author_name
						featured_image {
							localFile {
								childImageSharp {
									fixed(width: 1200) {
										src
									}
								}
							}
						}
					}
				}
			}
		}
		wordpressPage(wordpress_id: { eq: 83 }) {
			title
			slug
			yoast_meta {
				yoast_wpseo_title
				yoast_wpseo_metadesc
			}
			acf {
				page_cover

				posts_list {
					post {
						post_title
						wordpress_id
						post_author
						post_date
						post_date_gmt
						post_content
						post_title
						post_excerpt
						post_status
						comment_status
						ping_status
						post_password
						post_name
						to_ping
						pinged
						post_modified
						post_modified_gmt
						post_content_filtered
						post_parent
						guid
						menu_order
						post_type
						post_mime_type
						comment_count
						filter
						acf {
							author_name
							featured_image {
								localFile {
									childImageSharp {
										fixed(width: 1200) {
											src
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
